var player;

function onYouTubePlayerAPIReady() {
	player = new YT.Player('video', {
		events: {
			'onReady': onPlayerReady,
			'onStateChange': onStateChange
		}
	});
}

function onPlayerReady(event) {
	jQuery('.watch-button').click( function () {
		jQuery('.youtube-poster').fadeOut();
		player.playVideo();
	})
}

function onStateChange(state) {
	var state_data = state['data'];
	console.log(state_data);

	if( state_data == 0 || state_data == 2 ) {
		jQuery('.youtube-poster').fadeIn();
	}
}