/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

/*global jQuery*/
/* global ES_CONFIG: */
/* global YT */

(function($) {
	var App = {
		common: function() {
			//Init all objects that will be fired on all pages here.
			$(document).foundation();
			this.image_resize.init('.featured-image-container');

			$( window ).resize(function() {
				App.image_resize.init('.featured-image-container');
			});

			$('.menu-button').click( function () {
				$(this).toggleClass('toggled');
				$('.mobile-menu-row').toggleClass('visible');

				if ( $(this).hasClass('toggled') ) {
					$(this).text('Close');
				} else {
					$(this).text('Menu');
				}
			});

		},
		image_resize: {
			init: function (selector) {
				var win				= $(window),
					selector_obj	= $(selector),
					selector_height_percent = selector_obj.attr('data-image-height'),
					win_height 		= parseInt(win.outerHeight(true) * selector_height_percent);

				selector_obj.css('height', win_height);
				selector_obj.find('.overlay').css('height', win_height);
				selector_obj.find('.title-container').css('margin-top', (win_height / 2) - ($('.title-container').outerHeight() + $('header').outerHeight()));
			},

			grid: function (selector) {
				var selector_obj = $(selector);
				selector_obj.find('li').each( function () {
					$(this).find('.news-container').height( Math.round( $(this).width() / 100 ) * 100 );
					$(this).find('.overlay').height( Math.round( $(this).width() / 100 ) * 100 );
				});
			}
		},
		youtube_player: {
			init: function (selector) {
				var win = $(window),
					selector_obj = $(selector).find('iframe'),
					win_height = win.outerHeight(true),
					win_width = win.width();

				selector_obj.attr('height', win_height);
				selector_obj.attr('width', win_width);

				$(selector).css('height', win_height);
			},

			blog: function (selector) {
				var selector_obj = $(selector).find('iframe'),
					sel_height = $(selector).outerHeight(true),
					sel_width = $(selector).outerWidth(true),
					win_width = $(window).width();

				console.log(sel_width);

				if( win_width < 1024 ) {
					sel_height = 512;
					$(selector).css('height', sel_height);
				} else {
					sel_height = $('.page-content').outerHeight(true);
					$(selector).css('height', sel_height);
				}

				selector_obj.css('height', sel_height);
				selector_obj.css('width', sel_width);
			}
		}
	};

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Router = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				App.common();
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				App.youtube_player.init('.youtube-player');

				$( window ).resize(function() {
					App.youtube_player.init('.youtube-player');
				});

				$('.slick-container').slick({
					dots: false,
					infinite: true,
					arrows: false,
					slidesToShow: 9,
					autoplay: false,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 3,
								centerMode: true
							}
						},
						{
							breakpoint: 640,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								centerMode: true
							}
						},
					]
				});

				$('.merchandise-section').slick({
					dots: false,
					infinite: true,
					arrows: false,
					slidesToShow: 3,
					autoplay: false,
					responsive: [
						{
							breakpoint: 640,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								centerMode: true
							}
						},
					]
				});

				$('.results-button').click( function () {
					var race_id = $(this).attr('data-post-id'),
						race_player,
						ajax_args = {
							url: ES_CONFIG.admin_url,
							type: 'POST',
							nonce: ES_CONFIG.nonce,
							data: {
								action: 'race-result',
								race_id: race_id
							}
						};
					$('.calendar-container.toggled').removeClass('toggled');
					$.ajax(ajax_args).done( function(ret){
						$('.overview-container').html(ret);
						$('.overview-container').addClass('visible');
						$('.race-map').addClass('hidden');
						$('.overview-container iframe').height( $('.overview-container').height());
						race_player = new YT.Player('race-video', {
							events: {
								'onReady': onRacePlayerReady,
								'onStateChange': onRaceStateChange
							}
						});

						function onRacePlayerReady() {
							$('.watch-race-button').click( function () {
								$('.race-poster').fadeOut();
								race_player.playVideo();
							});
						}

						function onRaceStateChange(state) {
							var state_data = state['data'];

							if( state_data == 0 || state_data == 2 ) {
								$('.race-poster').fadeIn();
							}
						}

					});
					$(this).parents('.calendar-container').addClass('toggled');
					
				});

				$('.social-item').each( function () {
					$(this).find('.social-post').outerHeight( Math.round( $(this).width() / 10) * 10);
					$(this).find('.overlay').outerHeight( Math.round( $(this).width() / 10) * 10);
				});
			},
			finalize: function() {

			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		},
		// Single Merchandise page, note the change from about-us to about_us.
		'single_merchandise': {
			init: function() {
				var quantity_value = +$('.quantity').val();
				$('.plus-button').click( function () {
					quantity_value = +$('.quantity').val();
					$('.quantity').val( quantity_value + 1 );
				});

				$('.minus-button').click( function () {
					quantity_value = +$('.quantity').val();
					if( quantity_value - 1 > 0 ) {
						$('.quantity').val( quantity_value - 1 );
					}
				});
			}
		},

		'blog': {
			init: function () {
				$('.filter-button').click( function () {
					$('.filter-button').removeClass('toggled');
					$(this).addClass('toggled');
					var category_id = $(this).attr('data-category'),
						ajax_args = {
							url: ES_CONFIG.admin_url,
							type: 'POST',
							nonce: ES_CONFIG.nonce,
							data: {
								action: 'blog_posts',
								category_id: category_id
							}
						};
					$.ajax(ajax_args).done( function(ret){
						$('.latest-news-grid').html(ret);
						App.image_resize.grid('.latest-news-grid');
					});
				});

				App.image_resize.grid('.latest-news-grid');
			}
		},
		'single' : {
			init: function () {
				App.youtube_player.blog('.video-player');
				$( window ).resize(function() {
					App.youtube_player.blog('.video-player');
					App.image_resize.grid('.related-news');
				});
				App.image_resize.grid('.related-news');
			}
		},
		'archive' : {
			init: function () {
				$('.filter-button').click( function () {
					$('.filter-button').removeClass('toggled');
					$(this).addClass('toggled');
					var category_id = $(this).attr('data-category'),
						ajax_args = {
							url: ES_CONFIG.admin_url,
							type: 'POST',
							nonce: ES_CONFIG.nonce,
							data: {
								action: 'blog_posts',
								category_id: category_id
							}
						};
					$.ajax(ajax_args).done( function(ret){
						$('.latest-news-grid').html(ret);
						App.image_resize.grid('.latest-news-grid');
					});
				});

				App.image_resize.grid('.latest-news-grid');
			}
		},
		'post_type_archive_calendar' : {
			init: function () {
				$('.filter-button').click( function () {
					$('.filter-button').removeClass('toggled');
					$(this).addClass('toggled');
					var races_year = $(this).attr('data-year'),
						ajax_args = {
							url: ES_CONFIG.admin_url,
							type: 'POST',
							nonce: ES_CONFIG.nonce,
							data: {
								action: 'races_posts',
								races_year: races_year
							}
						};
					$.ajax(ajax_args).done( function(ret){
						$('.flex-boxes').html(ret);
					});
				});
			}
		},
		'single_calendar' : {
			init: function () {
				$('iframe').height( $('.padded-content').outerHeight() );
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Router;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
